var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.ifSub}},[_c('a-form',{attrs:{"layout":"horizontal","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"职位名称","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name',
              {rules:[
                {type:'string',required:true,message:'职位名称不能为空'},
                {type:'string',max:30,message:'职位名称不能超过30个字符'},
                ],trigger:'change'}
              ]),expression:"['name',\n              {rules:[\n                {type:'string',required:true,message:'职位名称不能为空'},\n                {type:'string',max:30,message:'职位名称不能超过30个字符'},\n                ],trigger:'change'}\n              ]"}],attrs:{"placeholder":"输入职位名称"}})],1),_c('a-form-item',{attrs:{"label":"设置排序号","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sorter', {rules:[
        {type:'number',required:true,message:'排序号必须为数字'}],trigger:'change',initialValue:0}]),expression:"['sorter', {rules:[\n        {type:'number',required:true,message:'排序号必须为数字'}],trigger:'change',initialValue:0}]"}],staticStyle:{"width":"100%"},attrs:{"step":0.1,"placeholder":"输入排序号"}}),_c('span',{staticStyle:{"color":"#808695","font-size":"10px","line-height":"10px"}},[_vm._v("显示顺序，升序排列")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }